import React, { useEffect } from 'react'
import Script from 'next/script'
import Bugsnag from '@bugsnag/js'
import { SWRConfig } from 'swr'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import getConfig from 'next/config'
import Head from 'next/head'

import usePreserveScroll from 'lib/hooks/usePreserveScroll'
import { initializeWunderkind } from 'lib/helpers/wunderkind'
import useSession from 'lib/network/hooks/useSession'
import BugsnagLogger from 'lib/utils/bugsnagLogger'
import { publishEvent } from 'lib/events/tracking'
import { get } from 'lib/network/fetchers/everlane'

import { mediaStyle, MediaContextProvider } from 'components/core/BreakpointMedia'
import '@everlane/typography/font-faces.scss'
import 'styles/reset.scss'
import 'styles/base.scss'

import Event from 'types/Event'
import Cookies from 'types/Cookies'

const { BUGSNAG_CONFIG, RELEASE_STAGE } = getConfig().publicRuntimeConfig

Bugsnag.start({
  ...BUGSNAG_CONFIG,
  plugins: [new BugsnagPluginReact()],
  logger: RELEASE_STAGE === 'production' ? BugsnagLogger : null,
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

declare global {
  interface Window {
    Tools: any
  }
}

const App = ({ Component, pageProps }) => {
  usePreserveScroll()

  useEffect(() => {
    try {
      if (typeof global.window !== 'undefined') {
        global.window.Tools = { Event, publishEvent }
      }

      const setModalToDOMElement = async () => {
        let Modal = await import('react-modal')
        Modal = Modal.default
        Modal.setAppElement('#__next')
      }

      const setupBugsnagPerformanceMonitoring = async () => {
        const initializeBugsnagPerformanceMonitoring = (
          await import('lib/utils/bugsnagPerformance')
        ).default
        initializeBugsnagPerformanceMonitoring()
      }

      const callLandEvents = async () => {
        const Cookie = (await import('js-cookie')).default
        const EVERLANE_USER_COOKIE = Cookies.EVERLANE_USER
        publishEvent(Event.App.LAND)

        if (Cookie.get(EVERLANE_USER_COOKIE)) {
          publishEvent(Event.App.REPEAT_USER_LAND)
        }
      }

      setModalToDOMElement()
      setupBugsnagPerformanceMonitoring()
      callLandEvents()
    } catch (e) {
      Bugsnag.notify(e, event => {
        event.addMetadata('_AppError', { error: JSON.stringify(e) })
      })
    }
  }, [])

  const { data: session } = useSession()
  const { visitor } = session || {}

  initializeWunderkind(visitor)

  const { publicRuntimeConfig } = getConfig() || {}
  const kustomerApiKey = publicRuntimeConfig.KUSTOMER_TRACKING_API_KEY

  return (
    <ErrorBoundary>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <link
          rel="icon"
          href="https://media.everlane.com/image/upload/c_scale/c_fill,dpr_auto,f_auto,q_auto,w_auto/v1/static/favicon4.ico"
          type="image/x-icon"
        />
        <link
          rel="apple-touch-icon apple-touch-icon-precomposed"
          sizes="76x76"
          href="https://media.everlane.com/image/upload/c_fill,dpr_1,f_auto,g_face:center,h_76,q_auto,w_76/v1/static/1x_ipad.png"
        />
        <link
          rel="apple-touch-icon apple-touch-icon-precomposed"
          sizes="167x167"
          href="https://media.everlane.com/image/upload/c_fill,dpr_1,f_auto,g_face:center,h_167,q_auto,w_167/v1/static/2x_ipad_pro.png"
        />
        <link
          rel="apple-touch-icon apple-touch-icon-precomposed"
          sizes="152x152"
          href="https://media.everlane.com/image/upload/c_fill,dpr_1,f_auto,g_face:center,h_152,q_auto,w_152/v1/static/2x_ipad.png"
        />
        <link
          rel="apple-touch-icon apple-touch-icon-precomposed"
          sizes="120x120"
          href="https://media.everlane.com/image/upload/c_fill,dpr_1,f_auto,g_face:center,h_120,q_auto,w_120/v1/static/2x_iphone.png"
        />
        <link
          rel="apple-touch-icon apple-touch-icon-precomposed"
          sizes="180x180"
          href="https://media.everlane.com/image/upload/c_fill,dpr_1,f_auto,g_face:center,h_120,q_auto,w_120/v1/static/3x_iphone.png"
        />
        <link rel="preconnect" href="https://tst.kaptcha.com" />
        <link rel="preconnect" href="https://media.everlane.com" />
        <link rel="preconnect" href="https://cdn.builder.io" />
        {/* eslint-disable-next-line react/no-danger */}
        <style type="text/css" dangerouslySetInnerHTML={{ __html: mediaStyle }} />
      </Head>
      <Script
        src="https://cdn.shopify.com/shopifycloud/shop-js/shop-pay-payment-request.js"
        strategy="afterInteractive"
      />
      <Script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"
        strategy="afterInteractive"
      />

      <Script
        src="https://cdn.kustomerapp.com/chat-web/widget.js"
        data-kustomer-api-key={kustomerApiKey}
      />

      <SWRConfig
        value={{
          isPaused: () => !session,
          fetcher: get,
          onErrorRetry: error => {
            // Never retry on 4xx.
            // eslint-disable-next-line no-useless-return
            if (error.status >= 400 && error.status < 500) return
          },
          revalidateIfStale: false,
        }}
      >
        <MediaContextProvider>
          <Component {...pageProps} />
        </MediaContextProvider>
      </SWRConfig>
    </ErrorBoundary>
  )
}

export default App
